import React, { useState, useRef } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TextField,
    TableRow,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Paper,
    Typography,
    Container,
    Alert
} from '@mui/material';
import SignatureCanvas from "react-signature-canvas";
import swal from 'sweetalert';
import styled from 'styled-components';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { generatePDF } from './calidadPDFen'
import { EvaluacionProveedor, ingresoPDFWorkmanagerFlujo, ingresoDOCXWorkmanagerFlujo } from '../FunctionLogin'

const EvaluationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  max-width: 1200px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

const EvaluationTitle = styled(Typography)`
  margin-bottom: 20px;
`;

const TableContainerStyled = styled(TableContainer)`
  margin-top: 20px;
  margin-bottom: 40px;
`;

const TableCellHead = styled(TableCell)`
  font-weight: bold;
  background-color: #f5f5f5;
`;

const TableCellBody = styled(TableCell)`
  font-size: 14px;
  padding: 12px;
`;

const SelectFormControl = styled(FormControl)`
  min-width: 80px;
`;

export default function EvaluationCalidaden() {


    const [loading, setLoading] = useState(false);
    const [codigoInternoProveedor, setCodigoInternoProveedor] = useState(localStorage.Proveedor);
    const [nombreProveedor, setNombreProveedor] = useState(localStorage.Tproveedor);
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [numeroDocumento, setNumeroDocumento] = useState('');
    const [direccion, setDireccion] = useState('');
    const [prestadorBienesServicios, setPrestadorBienesServicios] = useState('');
    const [evaluador, setEvaluador] = useState('');
    const [cargo, setCargo] = useState('');
    const [categoria, setCategoria] = useState('');
    const [fechaEvaluacion, setFechaEvaluacion] = useState('');
    const [periodoEvaluado, setPeriodoEvaluado] = useState('');
    const [correoProveedor, setCorreoProveedor] = useState('');
    const sigPad = useRef({});
    const sigPad2 = useRef({});
    const [firmaFile, setFirmaFile] = useState(null);
    const [firmaFile2, setFirmaFile2] = useState(null);
    const [observacion, setObservacion] = useState('');

    const [responsableVisita, setResponsableVisita] = useState('');
    const [responsableRecibir, setResponsableRecibir] = useState('');

    const handleCodigoInternoProveedorChange = (event) => {
        setCodigoInternoProveedor(event.target.value);
    };

    const handleNombreProveedorChange = (event) => {
        setNombreProveedor(event.target.value);
    };

    const handleTipoDocumentoChange = (event) => {
        setTipoDocumento(event.target.value);
    };

    const handleNumeroDocumentoChange = (event) => {
        setNumeroDocumento(event.target.value);
    };

    const handleDireccionChange = (event) => {
        setDireccion(event.target.value);
    };

    const handlePrestadorBienesServiciosChange = (event) => {
        setPrestadorBienesServicios(event.target.value);
    };

    const handleEvaluadorChange = (event) => {
        setEvaluador(event.target.value);
    };

    const handleCargoChange = (event) => {
        setCargo(event.target.value);
    };

    const handleCategoriaChange = (event) => {
        setCategoria(event.target.value);
    };

    const handleFechaEvaluacionChange = (event) => {
        setFechaEvaluacion(event.target.value);
    };

    const handlePeriodoEvaluadoChange = (event) => {
        setPeriodoEvaluado(event.target.value);
    };

    const handleCorreoProveedorChange = (event) => {
        setCorreoProveedor(event.target.value);
    };

    const handleObservacionChange = (event) => {
        setObservacion(event.target.value);
    };



    const evaluationData = [
        {
            "category": "QUALITY OF GOODS AND/OR SERVICES",
            "questions": [
                {
                    "question": "Does it meet the purchase order objective?",
                    "index": 0
                },
                {
                    "question": "Does the quality of the specifications of the good, product, or service meet the requirements?",
                    "index": 1
                },
                {
                    "question": "100% compliance in delivering the required quantities? 1: Does not meet 3: Meets",
                    "index": 2
                },
                {
                    "question": "Response time to warranties and claims? Delayed: 1 Intermediate: 2 Agile: 3",
                    "index": 3
                },
                {
                    "question": "Index of claims and rejections? High: 1 Medium: 2 Low: 3",
                    "index": 4
                }
            ]
        },
        {
            "category": "COMMERCIAL",
            "questions": [
                {
                    "question": "Payment method? Cash = 1 16-30 days = 2 31 days and beyond = 3",
                    "index": 5
                },
                {
                    "question": "Competitiveness in total acquisition cost (prices, added value, deliveries, freight, repurchases, additional shipping costs, etc.)?",
                    "index": 6
                },
                {
                    "question": "Supplier flexibility? (Minimum orders/Handling emergencies)",
                    "index": 7
                },
                {
                    "question": "Innovation (Proposals for improvement in competitiveness, technological updates, connectivity, proactive delivery tracking)",
                    "index": 8
                }
            ]
        },
        {
            "category": "RESPONSE TIMELINESS TO REQUIREMENTS",
            "questions": [
                {
                    "question": "Does the supplier provide timely responses to quotes, proformas, inquiries, advice, and/or general consultations? YES: 3 PARTIAL: 2 NO: 1",
                    "index": 9
                }
            ]
        },
        {
            "category": "ON-TIME ORDER DELIVERY COMPLIANCE",
            "questions": [
                {
                    "question": "What is the compliance level with delivery deadlines? (Rating: 1 if below 50%, rating: 2 if between 51% and 70%, rating: 3 from 90% and above)",
                    "index": 10
                }
            ]
        },
        {
            "category": "ENVIRONMENTAL",
            "questions": [
                {
                    "question": "Does the company have an environmental management plan?",
                    "index": 11
                },
                {
                    "question": "Does the company demonstrate the use of measures to reduce negative environmental impacts, such as carbon footprint measurement, water footprint, recycling, reuse, material packaging and packaging take-back, and effluent control? Does it have environmental certifications? (Rating: 3 if it meets all mentioned aspects, rating: 2 if it partially meets, rating: 1 if it does not meet)",
                    "index": 12
                }
            ]
        },
        {
            "category": "PSYCHOSOCIAL",
            "questions": [
                {
                    "question": "Does the company have plans for the management and control of psychosocial risks, occupational risks, safety, and health at work? (Rating: 3 if it has complete plans, rating: 2 if it has partial plans, rating: 1 if it does not have plans)",
                    "index": 13
                },
                {
                    "question": "Are volunteer activities, social works, and support for foundations encouraged?",
                    "index": 17
                },
                {
                    "question": "What benefits are granted to collaborators? (Fair wages, educational, economic, social incentives, among others)",
                    "index": 18
                }
            ]
        },
        {
            "category": "RELIABLE BUSINESS",
            "questions": [
                {
                    "question": "Does the company have a code of ethics and conduct that applies to employees, suppliers, shareholders, and others? (Rating: 3 if it has a complete code, rating: 2 if it has a partial code, rating: 1 if it does not have a code)",
                    "index": 14
                }
            ]
        },
        {
            "category": "SUPPLIER DOCUMENTATION",
            "questions": [
                {
                    "question": "Does the company comply with TALSA's internal purchasing policies and provide the required documentation for supplier selection, evaluation, and monitoring? (Rating: 3 if documents are complete, rating: 2 if documents are incomplete, rating: 1 if documentation is not provided)",
                    "index": 15
                }
            ]
        },
        {
            "category": "AFTER-SALES SERVICES",
            "questions": [
                {
                    "question": "Does the company meet the service level promises, such as support, warranties, product or service replacement, and provide advice and training? (Rating: 3 if it meets all promises, rating: 2 if it partially meets, rating: 1 if it does not meet)",
                    "index": 16
                },
                {
                    "question": "Does the company demonstrate proactivity and perform timely follow-up on deliveries and service coverage nationwide, including TALSA locations? (Rating: 3 if proactive and performs timely follow-up, rating: 2 if partially proactive, rating: 1 if not proactive and does not perform timely follow-up)",
                    "index": 17
                }
            ]
        }
    ];



    const asociados = [
        { tipo: 'FAVORABLE', puntaje: '> 80', accion: 'Highlight strengths to reinforce the current position and noteworthy aspects demonstrating good performance.', control: 'No verification required' },
        {
            tipo: 'CONDITIONALLY FAVORABLE', puntaje: '> 60 and <= 80', accion: 'Identify areas for improvement to implement corrective actions that demonstrate overcoming these weaknesses. A new evaluation will be scheduled within a maximum period of 6 months.',
            control: 'An occasional check will be conducted after three months to assess the performance and compliance of the supplier.'
        },
        {
            tipo: 'UNFAVORABLE', puntaje: '> 0 and <= 60', accion: 'Inform the supplier that they have not met the expected parameters to maintain the continuity of their services. This evaluation is considered UNFAVORABLE.',
            control: 'Study the action plans submitted by the supplier.'
        },
    ];


    const [evaluations, setEvaluations] = useState(Array(18).fill(0));

    const handleEvaluationChange = (index, value) => {
        setEvaluations((prevEvaluations) => {
            const newEvaluations = [...prevEvaluations];
            newEvaluations[index] = value;
            return newEvaluations;
        });
    };

    const [totalPoints, setTotalPoints] = useState(0);

    const sumPoints = () => {
        const sum = evaluations.reduce((total, evaluation) => total + evaluation, 0);
        setTotalPoints(sum);
    };

    return (
        <EvaluationContainer>


            <TableContainerStyled component={Paper}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography>INTERNAL SUPPLIER CODE:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={codigoInternoProveedor}
                                    onChange={handleCodigoInternoProveedorChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>SUPPLIER NAME:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={nombreProveedor}
                                    onChange={handleNombreProveedorChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>DOCUMENT TYPE:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={tipoDocumento}
                                    onChange={handleTipoDocumentoChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>DOCUMENT NUMBER:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={numeroDocumento}
                                    onChange={handleNumeroDocumentoChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>ADDRESS:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={direccion}
                                    onChange={handleDireccionChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>GOODS OR SERVICES PROVIDER:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={prestadorBienesServicios}
                                    onChange={handlePrestadorBienesServiciosChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>EVALUATOR:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={evaluador}
                                    onChange={handleEvaluadorChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>JOB TITLE:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={cargo}
                                    onChange={handleCargoChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>CATEGORY:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={categoria}
                                    onChange={handleCategoriaChange}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography>EVALUATION DATE:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="date"
                                    value={fechaEvaluacion}
                                    onChange={handleFechaEvaluacionChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>EVALUATED PERIOD:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={periodoEvaluado}
                                    onChange={handlePeriodoEvaluadoChange}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>SUPPLIER EMAIL:</Typography>
                                <TextField
                                    fullWidth={25}
                                    type="text"
                                    value={correoProveedor}
                                    onChange={handleCorreoProveedorChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainerStyled>



            <TableContainer component={Paper} style={{ width: "100%", border: "1px solid", margin: "auto", padding: "0px" }}>
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <h4>PUNCTUATION SYSTEM</h4>
                            </TableCell>
                            <TableCell>
                                <h4>Category</h4>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                1
                            </TableCell>
                            <TableCell>
                                Fails
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                2
                            </TableCell>
                            <TableCell>
                                Partially compliant
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                3
                            </TableCell>
                            <TableCell>
                                Compliant
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Container>
                <TableContainerStyled component={Paper} style={{ maxHeight: "400px", overflowY: "auto" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Category</TableCellHead>
                                <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Questions</TableCellHead>
                                <TableCellHead style={{ position: "sticky", top: 0, zIndex: 1 }}>Assessment</TableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {evaluationData.map((category, categoryIndex) => (
                                <React.Fragment key={categoryIndex}>
                                    {category.questions.map((question, questionIndex) => (
                                        <TableRow key={`${categoryIndex}-${questionIndex}`}>
                                            {questionIndex === 0 && (
                                                <TableCellHead style={{ textAlign: "center" }} rowSpan={category.questions.length}>{category.category}</TableCellHead>
                                            )}
                                            <TableCellBody>{question.question}</TableCellBody>
                                            <TableCellBody>
                                                <SelectFormControl>
                                                    <Select
                                                        value={evaluations[question.index]}
                                                        onChange={(event) => handleEvaluationChange(question.index, event.target.value)}
                                                    >
                                                        <MenuItem value={1}>1</MenuItem>
                                                        <MenuItem value={2}>2</MenuItem>
                                                        <MenuItem value={3}>3</MenuItem>
                                                    </Select>
                                                </SelectFormControl>
                                            </TableCellBody>
                                        </TableRow>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainerStyled>
            </Container>



            <TableContainerStyled component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCellHead>Observation</TableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableCellBody>
                            <TextareaAutosize
                                style={{ width: '100%', resize: 'none' }}
                                value={observacion}
                                onChange={handleObservacionChange}
                                rows={8} // Puedes ajustar el número de filas según tus necesidades
                            />
                        </TableCellBody>
                    </TableBody>
                </Table>
            </TableContainerStyled>




            <br></br>
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell >Qualification</TableCell>
                            <TableCell >Score</TableCell>
                            <TableCell >Action to follow</TableCell>
                            <TableCell >Control</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {asociados.map((asociado, index) => (
                            <TableRow key={index}>
                                <TableCell>{asociado.tipo}</TableCell>
                                <TableCell>{asociado.puntaje}</TableCell>
                                <TableCell>{asociado.accion}</TableCell>
                                <TableCell>{asociado.control}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h6">Qualification: {((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2)} %</Typography>
            <Typography variant="h6">Qualification: {
                (((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 80 ? "FAVORABLE" : ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 60
                    && ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) <= 80 ? "FAVORABLE CONDICIONAL" : "NO FAVORABLE")
            }</Typography>


            <div>
                <TextField
                    placeholder='RESPONSIBLE FOR EXECUTING THE EVALUATION'
                    style={{ width: "350px", padding: "15px" }}
                    value={responsableVisita}
                    onChange={(e) =>
                        setResponsableVisita(e.target.value)
                    }
                />
                <SignatureCanvas
                    canvasProps={{
                        width: 550,
                        height: 150,
                        style: { "border": "1px solid #000000" }
                    }}
                    minWidth={1}
                    maxWidth={1}
                    penColor="black"
                    ref={sigPad}
                />
                <br /><Button onClick={() => {
                    sigPad.current.clear()
                    setFirmaFile(null)
                }}
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }}>
                    Clear     </Button>
                <Button variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }} onClick={() => {
                        sumPoints()
                        const canvas = sigPad.current.getCanvas();
                        const base64Data = canvas.toDataURL(); // Obtener el valor base64 de la imagen dibujada
                        setFirmaFile(base64Data)
                        swal("thank you", "Signature saved successfully", "success");
                    }}>Save</Button>
            </div>





            <div>
                <TextField
                    placeholder='RESPONSIBLE FOR EXECUTING THE EVALUATION'
                    style={{ width: "350px", padding: "15px" }}
                    value={responsableRecibir}
                    onChange={(e) =>
                        setResponsableRecibir(e.target.value)
                    }
                />
                <SignatureCanvas
                    canvasProps={{
                        width: 550,
                        height: 150,
                        style: { "border": "1px solid #000000" }
                    }}
                    minWidth={1}
                    maxWidth={1}
                    penColor="black"
                    ref={sigPad2}
                />
                <br /><Button onClick={() => {
                    sigPad2.current.clear()
                    setFirmaFile2(null)
                }}
                    variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }}>
                    Clear     </Button>
                <Button variant="contained"
                    component="label"
                    color="primary"
                    style={{ margin: "10px" }} onClick={() => {
                        sumPoints()
                        const canvas = sigPad2.current.getCanvas();
                        const base64Data = canvas.toDataURL(); // Obtener el valor base64 de la imagen dibujada
                        setFirmaFile2(base64Data)
                        swal("thank you", "Signature saved successfully", "success");
                    }}>Save</Button>
            </div>

            <Alert severity="warning">You must sign the form and then click the button to send information.</Alert>

            <Button variant="contained" disabled={loading} color="primary" onClick={() => {
                try {


                    setLoading(true)

                    const tableData = [];
                    evaluationData.forEach((category, categoryIndex) => {
                        const categoryData = {
                            category: category.category,
                            questions: [],
                        };
                        category.questions.forEach((question, questionIndex) => {
                            const questionData = {
                                question: question.question,
                                evaluation: evaluations[question.index] || "",
                            };
                            categoryData.questions.push(questionData);
                        });
                        tableData.push(categoryData);
                    });
                    const camposRequeridos = [
                        { campo: 'nombreProveedor', valor: nombreProveedor, mensaje: 'name Provider' },
                        { campo: 'tipoDocumento', valor: tipoDocumento, mensaje: 'Document type' },
                        { campo: 'numeroDocumento', valor: numeroDocumento, mensaje: 'Document number' },
                        { campo: 'direccion', valor: direccion, mensaje: 'Address' },
                        { campo: 'correoProveedor', valor: correoProveedor, mensaje: 'Supplier email' },
                        { campo: 'prestadorBienesServicios', valor: prestadorBienesServicios, mensaje: 'Provider of goods/services' },
                        { campo: 'categoria', valor: categoria, mensaje: 'Category' },
                        { campo: 'evaluador', valor: evaluador, mensaje: 'Evaluator' },
                        { campo: 'cargo', valor: cargo, mensaje: 'Post' },
                        { campo: 'responsableVisita', valor: responsableVisita, mensaje: 'Responsible for the visit' },
                        { campo: 'responsableRecibir', valor: responsableRecibir, mensaje: 'Responsible for receiving' },
                    ];

                    for (let campo of camposRequeridos) {
                        if (!campo.valor || campo.valor.trim() === '') {
                            swal({
                                title: 'The document must be signed!',
                                text: `Campo faltante: ${campo.mensaje}`,
                                icon: 'warning',
                            });

                            setLoading(false)
                            return false;
                        }
                    }


                    if (firmaFile == null || firmaFile2 == null) {
                        swal("The document must be signed!", "Sign, save and send!", "warning");

                        setLoading(false)
                        return;
                    }
                    const jsonData = JSON.stringify(tableData);
                    var raw = JSON.stringify({
                        "datos": `${jsonData}`,
                        "nombreproveedor": `${nombreProveedor}`,
                        "tipodocumento": `${tipoDocumento}`,
                        "numeroducumento": `${numeroDocumento}`,
                        "direccion": `${direccion}`,
                        "correoelectronico": `${correoProveedor}`,
                        "prestadorbienes": `${prestadorBienesServicios}`,
                        "categoria": `${categoria}`,
                        "evaluador": `${evaluador}`,
                        "cargo": `${cargo}`,
                        "firma1": `${firmaFile}`,
                        "firma2": `${firmaFile2}`,
                        "periodoEvaluado": `${periodoEvaluado}`,
                        "responsable": `${responsableVisita}`,
                        "responsable2": `${responsableRecibir}`,
                        "obsersaciones": `${observacion}`,
                        "favorabl": `${((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2)}`,
                        "favorable": `${(((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 80 ? "FAVORABLE" : ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) > 60
                            && ((totalPoints / ((evaluations.length) * 3)) * 100).toFixed(2) <= 80 ? "FAVORABLE CONDICIONAL" : "NO FAVORABLE")}`

                    });
                    console.log(firmaFile)
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: raw,
                        redirect: 'follow'
                    };
                    fetch("https://consumos.talsa.com.co/api/talsa/pdf/v1/pdfcalidaden", requestOptions)
                        //fetch("http://localhost:3001/api/talsa/pdf/v1/pdfcalidaden", requestOptions)
                        .then(response => {
                            if (response.status == 200) {
                                return response.text(); // Return the response text to the next then
                            } else {
                                swal("Error", "The document was not created successfully", "error");
                                setLoading(false)
                                throw new Error('The document was not created successfully');
                            }
                        })
                        .then(responseText => {
                            const responseJson = JSON.parse(responseText);
                            const base64 = responseJson.base64;
                            console.log(base64)
                            // Here you have the base64 string
                            EvaluacionProveedor(codigoInternoProveedor, nombreProveedor, tipoDocumento, numeroDocumento, direccion, prestadorBienesServicios,
                                evaluador, cargo, categoria, fechaEvaluacion, periodoEvaluado, correoProveedor, jsonData, observacion).then((result) => {
                                    ingresoDOCXWorkmanagerFlujo(base64, result.workflowId, "evaluacioncalidaden").then(() => {
                                        swal("Successful", "The document was created successfully", "success");
                                    })
                                })
                        }).then(result => console.log(result))
                        .catch(error => console.log('error', error));

                } catch (error) {
                    console.error('Error al descargar el archivo:', error.message);
                }
            }} >
                Send information
            </Button>

        </EvaluationContainer>
    );
}
