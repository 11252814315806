import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import contrasena from '../recursos/contrasena.png';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField } from '@mui/material';
import { getIDWorkmanager, encrypt, EnviarCorreo, ActualizarDatos,loginauth } from '../Componentes/FunctionLogin';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next'

export default function Contrasena() {
    const [t, i18next] = useTranslation("global");
    const [nit, setNit] = useState('');
    const [nitEncriptado, setNitEncriptado] = useState('');
    const [loading, setLoading] = useState(false);

    const handleNitChange = (event) => {
        setNit(event.target.value);
    };


    const handleEncryptClick = () => {
        setLoading(true);
        getIDWorkmanager(nit).then((result) => {
            result.forEach((element) => {
                if (element.Field === 'EMAIL') {
                    const value = element.Value;
                    setNitEncriptado(value);
                    encrypt(Math.random().toString().slice(2, 6)).then((contrasena) => {
                             EnviarCorreo(value, contrasena.slice(1, 14)).then((datos) => {
                                 if (datos === "true") {
                                     ActualizarDatos(nit, contrasena.slice(1, 14)).then((resultActualizar) => {
                                         swal("Correcto!", "Datos enviados con exito", "success");
                                         setLoading(false);
                                     });
                                 }
                             });
                    });
                }
            });
        });
    };



    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="sm" style={{ padding: '50px' }}>
                <Card sx={{ maxWidth: 600 }}>
                    <CardMedia
                        sx={{
                            height: 0,
                            paddingTop: '100%', // Relación de aspecto 1:1 (100% = 1 / 1 * 100)
                            objectFit: 'cover', // Hace que la imagen se vea completa y se ajuste al contenedor
                        }}
                        image={contrasena}
                        title="contraseña"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {t("contrasena.envio")}
                        </Typography>

                        <Box
                            sx={{ display: 'flex', flexDirection: 'column', gap: '16px', maxWidth: '650px' }}
                        >
                            <TextField label="ID" variant="outlined" value={nit} onChange={handleNitChange} />
                            <TextField
                                label={t("contrasena.correo")}
                                variant="outlined"
                                value={nitEncriptado}
                                disabled
                            />
                            <Button variant="contained" onClick={handleEncryptClick} disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : t("contrasena.restaurar")}
                            </Button>

                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </React.Fragment>
    );
}
