import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import configuracion from '../recursos/configuracion.png';
import { useNavigate } from 'react-router-dom';
import { decrypt } from '../Componentes/FunctionLogin.js';
import CambioContrasena from '../Componentes/cambioContrasena.js';
import CambioProveedor from '../Componentes/cambioproveedor.js';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Popover from '@mui/material/Popover';


const settings = [{ texto: 'Cerrar', codigo: 'Cerrar' }];

function ResponsiveAppBar() {
  const [t, i18n] = useTranslation('global');
  const navigate = useNavigate();
  let [pages, setPages] = React.useState([]);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [cambiarPerfil, setCambiarPerfil] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);


  const handleOpenNavMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseNavMenu = (page) => {
    setIsMenuOpen(false);
    if (page._reactName !== 'onClick') {
      navigate('/proveedores/' + page);
    }
  };

  const handleMenuCloseLogin = (seting) => {
    Cookies.remove('sessionId');
    navigate('/proveedores/' + seting);
  };

  const handleOpenUserMenu = (event) => {
    setPopoverOpen(true);
    setPopoverAnchor(event.currentTarget); // Establece el anchor correctamente
  };
  
  const handleCloseUserMenu = () => {
    setPopoverOpen(false);
  };

  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [popoverAnchor, setPopoverAnchor] = React.useState(null);

  React.useEffect(() => {
    decrypt(localStorage.TipoDeUsuario).then((result) => {
      if (result === 'COLABORADORES') {
        if (localStorage.workmanagerTipoUsuario === 'TECNICO') {
          setPages([{ texto: 'documentación', codigo: 'documentación' }]);
        } else if (localStorage.workmanagerTipoUsuario === 'COMERCIAL') {
          setPages([{ texto: 'documentación', codigo: 'documentación' },
          { texto: t('menu.estadodecuenta'), codigo: 'estado de cuenta' },
          { texto: t('menu.ordenes'), codigo: 'órdenes' },
          ]);
        } else {
          setPages([
            { texto: t('menu.ordenes'), codigo: 'órdenes' },
            { texto: t('menu.estadodecuenta'), codigo: 'estado de cuenta' },
            { texto: t('menu.evaluacion'), codigo: 'evaluación' },
            { texto: t('menu.evaluacionproveedor'), codigo: 'evaluación proveedor' },
            { texto: t('menu.all'), codigo: 'all' },
            { texto: t('menu.generarlinkproveedores'), codigo: 'generar link proveedores' },
            { texto: t('menu.documentacion'), codigo: 'documentación' },
            { texto: "Formato F-CS-LG-16", codigo: 'Formato F-CS-LG-16' },
          ]);
        }
        setCambiarPerfil(true);
      } else {
        setCambiarPerfil(false);
        setPages([
          { texto: t('menu.ordenes'), codigo: 'órdenes' },
          { texto: t('menu.estadodecuenta'), codigo: 'estado de cuenta' },
          { texto: t('menu.documentacion'), codigo: 'documentación' },
          { texto: t('menu.evaluacionproveedor'), codigo: 'evaluación proveedor' },
        ]);
      }
    });
  }, []);

  return (
    <AppBar position="static" style={{ backgroundColor: '#BF2642' }}>
      <Drawer anchor="left" open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <List >
          {pages.map((page) => (
            <ListItem button key={page} onClick={() => handleCloseNavMenu(page.codigo)} >
              <ListItemText primary={page.texto} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box maxWidth="100%" >
        <Toolbar disableGutters>
          <IconButton
            size="large"
            aria-label="open navigation menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 100, display: { xs: 'none', md: 'flex' } }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href=""
              sx={{
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.2rem',
                color: '#ffff',
                textDecoration: 'none',
                textAlign: "center"
              }}
            >{localStorage.Tproveedor}-Categoria:{localStorage.Categoria}-Criticidad({localStorage.Criticidad})</Typography>
          </Box>

          <Box sx={{ flexGrow: 2, display: 'flex', justifyContent: "end", margin: "10px", flexDirection: 'row' }}>

            <Tooltip title="Open menu">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src={configuracion} />
              </IconButton>
            </Tooltip>
            <Popover
              open={popoverOpen}
              anchorEl={popoverAnchor}
              onClose={handleCloseUserMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <Box sx={{ p: 4, width: 400 }}>
                <MenuItem key={1}>
                  <CambioContrasena />
                </MenuItem>
                {cambiarPerfil ? (
                  <MenuItem key={2}>
                    <CambioProveedor style={{ width: '100%' }} />
                  </MenuItem>
                ) : (
                  <MenuItem key={1}></MenuItem>
                )}
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography
                      textAlign="center"
                      onClick={() => {
                        handleMenuCloseLogin(setting.codigo);
                      }}
                    >
                      {setting.texto}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </Popover>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

export default ResponsiveAppBar;
